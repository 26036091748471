import React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { DownloadBox, Layout, SectionTitle, SEO } from "components";
import { useTranslation } from "react-i18next";
import { path } from "constants/common";

const BlogPost = ({ data }: RootObject) => {
  const { t } = useTranslation();

  const removeBreaks = (text: string) => {
    return text.replace(/(\\r\\n|\\n|\\r|``)/gm, " ");
  };

  return (
    <Layout>
      <SEO
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description}
      />
      <article className="materials container">
        <div className="materials__top">
          <Link
            to={path.materials}
            className="materials__back"
          >{`< ${t`config.button.back`}`}</Link>
          {data?.download?.edges?.[0]?.node && (
            <DownloadBox link={data.download.edges[0].node.publicURL} />
          )}
        </div>
        <SectionTitle title={data.mdx.frontmatter.title} center />
        <div className="materials__date">{data.mdx.frontmatter.date}</div>
        <div className="materials__content">
          <MDXRenderer>{removeBreaks(data.mdx.body)}</MDXRenderer>
        </div>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String, $language: String!, $myPath: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    download: allFile(
      filter: { extension: { eq: "pdf" }, relativeDirectory: { in: [$myPath] } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;

export default BlogPost;

export interface Frontmatter {
  title: string;
  date: string;
  description: string;
}

export interface Mdx {
  frontmatter: Frontmatter;
  body: string;
}

export interface Download {
  edges: {
    node: {
      publicURL: string;
    };
  }[];
}

export interface Data {
  mdx: Mdx;
  download?: Download;
}

export interface RootObject {
  data: Data;
}
